<template>
  <v-dialog
    v-model="value"
    width="fit-content"
    persistent
    :fullscreen="isMobile"
    :hide-overlay="isMobile"
    :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
  >
    <v-card class="role-card">
      <v-list :class="isMobile ? ['white', 'sidebar-top'] : ['white']">
        <v-list-item>
          <template v-if="isMobile">
            <v-list-item-action>
              <v-btn v-if="isMobile" text icon @click="closeShareTo">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-spacer></v-spacer>
          </template>

          <v-list-item-content>
            <v-list-item-title :style="isMobile ? {'text-align': 'center'} : {'font-size': '20px'}">Forward</v-list-item-title>
          </v-list-item-content>

          <v-spacer></v-spacer>

          <v-list-item-action>
            <v-btn v-if="isMobile" text icon @click="complete">
              <v-icon>check</v-icon>
            </v-btn>
            <v-btn v-else text icon @click="closeShareTo">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <div :style="{
          'position': 'absolute',
          'top': '64px',
          'bottom': '0',
          'left': '0',
          'right': '0',
          'overflow-x': 'hidden'
        }"
      >
        <forward-reviewer-list
          :members="myMembers"
          @change-members="changeMembers"
          :style="{
            'height': 'calc(100% - ' + (isMobile ? commentBoxRows * 18 + 47 : 260) + 'px)',
            'padding': '0 30px ' + (isMobile ? 64 : 12) + 'px 24px',
            'overflow': 'auto'
          }"
        >
        </forward-reviewer-list>

        <div
          class="contract-review-comments-container"
          :style="isMobile ? { 'padding': '0 0 3px' } : { 'padding': '16px 18px 0' }"
        >
          <v-textarea
            solo
            no-resize
            placeholder="Please input comments"
            :counter="commentsMax"
            v-model="comments"
            :rows="isMobile ? commentBoxRows : 5"
          ></v-textarea>
        </div>

        <div v-if="!isMobile" style="padding: 5px 16px;text-align: right;">
          <v-btn 
            :dark="!canSend"
            :color="btnColor"
            :disabled="canSend"
            @click="complete"
          >
            Send
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import forwardReviewerList from './forward-reviewer-list'

export default {
  components: {
    forwardReviewerList
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    btnColor: {
      type: String,
      default: 'primary'
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      myMembers: [],
      comments: null,
      commentsMax: 1000
    }
  },
  computed: {
    ...mapState('app', {
      windowWidth: state => state.windowSize.width,
      windowHeight: state => state.windowSize.height
    }),
    ...mapGetters('app', [
      'isMobile'
    ]),
    commentBoxRows () {
      return parseInt((this.windowHeight - 64 - 45) / 18 / 2)
    },
    canSend () {
      return !this.myMembers.length || !this.comments || !this.comments.toString().trim() || this.comments.length > this.commentsMax
    }
  },
  methods: {
    closeShareTo () {
      this.$emit('change', false)
    },
    changeMembers (members) {
      this.myMembers = members
      this.$emit('change-members', members)
    },
    complete () {
      this.$emit('complete', {
        to: this.myMembers,
        comments: this.comments
      })
    },
    clearAll () {
      this.myMembers = [],
      this.comments = ''
    }
  },
  created () {
    this.clearAll()
  },
  watch: {
    value: {
      handler (val, oVal) {
        this.clearAll()
      }
    }
  }
}
</script>

<style lang="sass">
.role-card
  width: 600px
  max-width: 100%
  overflow: hidden
  height: 520px

.contract-review-comments-container
  .v-input
    padding-top: 0px

  .v-input__slot
    padding: 0 9px 9px

  .person-select
    .v-input__slot
      padding: 9px
    .v-select__selections
      padding-left: 6px

  textarea
    margin-top: 0px !important
    padding-top: 8px !important
</style>
