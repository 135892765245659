export const docScrollTo = (offsetTop, step) => {
  step = step || 50
  offsetTop = offsetTop < 0 ? 0 : offsetTop
  const scrollTop = document.documentElement.scrollTop
  const diff = offsetTop - document.documentElement.scrollTop
  if (!diff) return
  if (Math.abs(diff) < step) {
    document.documentElement.scrollTop = offsetTop
    return
  }
  document.documentElement.scrollTop = diff > 0 ? (scrollTop + step) : (scrollTop - step)
  if (document.documentElement.scrollTop !== scrollTop) { // in case of out of size
    setTimeout(() => {
      docScrollTo(offsetTop, step)
    }, 10);
  }
}

export const docScrollToId = (elementId, step, offset) => {
  step = step || 50
  offset = offset || 0

  let el = document.getElementById(elementId)
  if (!el) return
  let offsetTop = el.offsetTop + offset

  docScrollTo(offsetTop, step)
}
