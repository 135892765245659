export const financeRule = {
  BillingTerms: {
    '50% upfront / 50% upon final delivery': { need: 'No', term: '50% upfront / 50% upon final delivery', Cn: '50%在项目合同或时间表签署时开具发票，50%在尼尔森递交最终报告时开具发票。', En: 'NielsenIQ shall issue to the Client an invoice for an amount of fifty percent (50%) of the Service Fee upon execution of this Contract. Upon delivery of the final deliverables to the Client, NielsenIQ shall issue antoher invoice for the amount of the remaining fifty percent (50%) of the Service Fee on delivery of the final report in substantial accordance with the final confirmed proposal. Service Fee shall be payable after the receipt of specific invoices.' },
    '100% upfront': { need: 'No', term: '100% upfront', Cn: '项目合同或时间表签署时开具全款发票。', En: 'NielsenIQ shall issue to the Client an invoice for the full amount of the Service Fee upon execution of this Contract. Service Fee shall be payable after the receipt of specific invoices.' },
    '100% upon final delivery': { need: 'Yes', term: '100% upon final delivery', Cn: '尼尔森递交最终报告时开具全款发票。', En: 'NielsenIQ shall issue to the Client an invoice for the full amount of the Service Fee upon delivery of the final deliverables to the Client. Service Fee shall be payable after the receipt of specific invoices.' },
    'Monthly': { need: 'No', term: 'Monthly', Cn: '每月结算开具发票。', En: 'NielsenIQ shall issue to the client an invoice by end of each month for the service delivered.' },
    'Others': { need: 'Yes', term: 'Others', Cn: '', En: '' }
  },
  PaymentTerms: {
    'Cash in Advance': { need: 'No', term: 'Cash in Advance', Cn: '立即', En: 'Invoice amount, including those for any applicable tax, are due immediately.' },
    'Net 7 days': { need: 'No', term: 'Net 7 days', Cn: '于7天内', En: 'Invoice amount, including those for any applicable tax, are due in 7 days.' },
    'Net 15 days': { need: 'No', term: 'Net 15 days', Cn: '于15天内', En: 'Invoice amount, including those for any applicable tax, are due in 15 days.' },
    'Net 30 days': { need: 'No', term: 'Net 30 days', Cn: '于30天内', En: 'Invoice amount, including those for any applicable tax, are due in 30 days.' },
    'Net 45 days': { need: 'Yes', term: 'Net 45 days', Cn: '于45天内', En: 'Invoice amount, including those for any applicable tax, are due in 45 days.' },
    'Net 60 days': { need: 'Yes', term: 'Net 60 days', Cn: '于60天内', En: 'Invoice amount, including those for any applicable tax, are due in 60 days.' },
    'End of Month 50': { need: 'Yes', term: 'End of Month 50', Cn: '于发票开具当月月末后50天内', En: 'Payment shall be due 50 days from the end of the such month.' },
    'Others': { need: 'Yes', term: 'Others', Cn: '', En: '' }
  },
  MsaLsa: {
    'Yes': { need: 'No', term: 'Yes', Cn: '', En: '' },
    'No': { need: 'Yes', term: 'No', Cn: '', En: '' },
    'N/A': { need: 'Yes', term: 'N/A', Cn: '', En: '' }
  }
}