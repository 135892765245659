var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"forward-review-list"},[_c('v-list-item',[_c('account-autocomplete',{model:{value:(_vm.searchedMember),callback:function ($$v) {_vm.searchedMember=$$v},expression:"searchedMember"}})],1),_c('v-list-item',{staticClass:"role-banner"},[_c('v-list-item-content',[_c('span',[_vm._v("Forward to "),_c('span',{staticStyle:{"color":"#ccc"}},[_vm._v("("+_vm._s(_vm.myMembers.length)+")")])])])],1),_c('div',{style:({
      'position': 'absolute',
      'top': '110px',
      'bottom': 0,
      'left': 0,
      'right': 0,
      'padding-left': '24px',
      'padding-right': (_vm.isMobile ? 30 : 12) + 'px',
      'padding-bottom': '0px',
      'overflow-y': 'scroll'
    })},[_vm._l((_vm.myMembers),function(member,mi){return [_c('v-list-item',{key:_vm.myMembers.length - mi},[_c('v-list-item-content',[_c('account-profile',{staticStyle:{"margin-left":"15px"},attrs:{"email":member.email,"name":member.name,"icon":member.icon,"tag-icon-size":32,"tag-color":_vm.themeColor.default,"gap-of-tag-icon-and-name":24}})],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"small":"","text":"","icon":""},on:{"click":function($event){return _vm.removeMember(mi)}}},[_c('v-icon',{attrs:{"color":"grey","size":"22px"}},[_vm._v(" mdi-close ")])],1)],1)],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }