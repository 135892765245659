<template>
  <transition
    name="fade"
    mode="out-in"
    @enter="enter"
    @leave="leave"
  >
    <div ref="flexible_list_container">
      <table v-if="!loading && isMini" key="flexible_table_mini" cellspacing="0" class="flexible-table">
        <template v-for="(item, ii) in items">
          <tr
            :key="'flrm_' + ii"
            :class="{ 'flexible-table-first-item': ii === 0 }"
            @mouseover="onMouseOverItem(ii)"
            @mouseout="onMouseOutItem(ii)"
            @click="clickItem(item, ii)"
          >
            <td v-if="expand" class="expansion-icon" 
              :style="{
                ...(valueStyle || {}), ...(item.valueStyle || {}), ...(hover === ii ? itemHoverStyle || {} : {}),
                'padding': '9px 0',
                'width': (expandIconSize + 8) + 'px',
                'text-align': 'center'
              }"
            >
              <v-icon :size="expandIconSize">mdi-chevron-{{expandIndexes.indexOf(ii) > -1 ? 'up' : 'down'}}</v-icon>
            </td>
            <td
              v-for="miniColumn in miniColumns"
              :key="'flrm_' + ii + '_col_' + miniColumn"
              :style="{ ...miniColumnsStyleFixed[miniColumn - 1], ...(hover === ii ? itemHoverStyle || {} : {}), ...(hover === ii ? miniItemHoverStyle || {} : {}) }"
            >
              <div
                v-for="(header, fi) in miniFields(miniColumn)"
                :key="'flfm_' + ii + '_' + fi"
                class="flexible-table-mini-cell"
                :style="{ ...(miniItemStyle || {}), ...(header.miniItemStyle || {}), ...(item.miniItemStyle || {}) }"
              >
                <div
                  v-if="!!header.miniTitle"
                  :style="{ ...(miniTitleStyle || {}), ...(header.miniTitleStyle || {}) }"
                >
                  {{ header.title }}<span v-html="header.miniTitle"></span>
                </div>
                <div>
                  <slot
                    v-if="header.slot"
                    :name="header.slot"
                    :item="item"
                    :index="ii"
                    :row="ii + 1"
                  ></slot>
                  <div v-else :style="{ ...(miniValueStyle || {}), ...(header.miniValueStyle || {}), ...(item.miniValueStyle || {}) }">
                    {{ item[header.value] }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <template v-if="expandIndexes.indexOf(ii) > -1">
            <slot
              name="expansion-mini"
              :item="item"
              :index="ii"
              :row="ii + 1"
            ></slot>
          </template>
        </template>
      </table>
      <table v-else key="flexible-table" cellspacing="0" class="flexible-table">
        <template v-if="!loading">
          <thead>
            <tr
              v-if="!isMini"
              class="flexible-table-header"
            >
              <td v-if="expand"></td>
              <template v-for="(header, fi) in headers">
                <td
                  v-if="!header.hideWidth || header.hideWidth < myWidth"
                  :key="'flf_' + fi"
                  :style="{ ...(titleStyle || {}), ...(header.titleStyle || {}) }"
                >
                  <slot
                    :name="`header-${header.slotHeader}`"
                    :title="header.title"
                  >
                    <div>{{ header.title }}</div>
                  </slot>
                </td>
              </template>
            </tr>
          </thead>
          <tbody class="flexible-table-body">
            <template v-for="(item, ii) in items">
              <tr
                :key="'flrn_' + ii"
                @mouseover="onMouseOverItem(ii)"
                @mouseout="onMouseOutItem(ii)"
                @click="clickItem(item, ii)"
                :class="{ 'flexible-table-first-item': ii === 0 }"
              >
                <td v-if="expand" class="expansion-icon" 
                  :style="{
                    ...(valueStyle || {}), ...(item.valueStyle || {}), ...(hover === ii ? itemHoverStyle || {} : {}),
                    'padding': '9px 0',
                    'width': (expandIconSize + 8) + 'px',
                    'text-align': 'center'
                  }"
                >
                  <v-icon :size="expandIconSize">mdi-chevron-{{expandIndexes.indexOf(ii) > -1 ? 'up' : 'down'}}</v-icon>
                </td>
                <template
                  v-for="(header, fi) in headers"
                >
                  <td
                    v-if="!header.hideWidth || header.hideWidth < myWidth"
                    :key="'flfn_' + ii + '_' + fi"
                    :style="{ ...(valueStyle || {}), ...(header.valueStyle || {}), ...(item.valueStyle || {}), ...(hover === ii ? itemHoverStyle || {} : {}) }"
                  >
                    <slot
                      :name="header.slot"
                      :item="item"
                      :index="ii"
                      :row="ii + 1"
                    >
                      <div v-if="items.findIndex(item => typeof(item[header.value]) !== 'undefined') > -1">
                        {{ item[header.value] }}
                      </div>
                    </slot>
                  </td>
                </template>
              </tr>
              <template v-if="expandIndexes.indexOf(ii) > -1">
                <slot
                  name="expansion"
                  :item="item"
                  :index="ii"
                  :row="ii + 1"
                ></slot>
              </template>
            </template>
          </tbody>
        </template>
      </table>
      <div v-if="!loading && items.length < 1" style="width: 100%;text-align: center;">
        <slot name="no-data">
          <div style="display: flex;align-items: center;justify-content: center;height: 60px;color: grey">
            {{ noDataText }}
          </div>
        </slot>
      </div>
      <div v-if="!!loading" style="display: flex;align-items: center;justify-content: center;height: 90px;">
        <v-progress-circular
          :width="4"
          color="grey"
          indeterminate
          :size="24"
        ></v-progress-circular>
        <div
          class="grey--text text--lighten-1"
          :style="{
            'margin-left': '9px'
          }"
        >
          {{ loading === true ? 'Loading' : loading }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    /**
     * required
     */
    headers: {
      type: Array,
      required: true
      /**
       * [
       *  {
       *    title: String,            // title in each column
       *    value: String,            // prop name of value
       *    mini: Boolean,            // if between 1 and prop 'miniColumns', show in specific column number. Otherwise not show.
       *    miniTitle: String,        // keep title in mini mode if not blank, string will be divider (v-html)
       *    slot: String,             // slot name in father
       *    titleStyle: Object,       // style of title in each column
       *    valueStyle: Object,       // style of value in each column
       *    miniItemStyle: Object,    // style of item (title & value) in each column in mini mode
       *    miniTitleStyle: Object,   // style of title in each column in mini mode
       *    miniValueStyle: Object,   // style of value in each column in mini mode
       *  }
       * ]
       */
    },
    items: {
      type: Array,
      required: true
      /**
       * [
       *    {
       *      prop1: value1,            // props & values
       *      prop2: value2,
       *      prop3: value3,
       *      ...,
       *      valueStyle: Object,       // style of value in each row
       *      miniItemStyle: Object     // style of item (title & value) in each row in mini mode
       *      miniValueStyle: Object    // style of value in each row in mini mode
       *    },
       *    ...
       * ]
       */
    },

    /**
     * acceptable
     */
    loading: {              // whether the list are loading
      type: [Boolean, String],
      default: false
    },

    // can expand
    expand: {
      type: Boolean,
      default: false
    },
    expandMini: {
      type: Boolean,
      default: false
    },
    expandIconSize: {
      type: Number,
      default: 14
    },

    // keep and put all headers whose prop 'mini' has valid value (!!mini = true) or self-width is less than mini size into one row
    mini: {
      type: Boolean,
      default: false
    },
    miniSize: {
      type: Number,
      default: 0
    },

    miniColumns: {          // divided to columns in mini mode, greater than 0
      type: Number,
      default: 1
    },
    miniColumnsStyle: {     // divided to columns in mini mode, greater than 0
      type: Array,
      default: () => ([])
      /**
       * elements should be object contains props in <colgroup> - <col>
       */
    },
    titleStyle: {           // style of title in all headers
      type: Object,
      default: () => ({})
    },
    valueStyle: {           // style of value in all rows
      type: Object,
      default: () => ({})
    },
    miniItemStyle: {        // style of item in all rows in mini mode
      type: Object,
      default: () => ({})
    },
    miniTitleStyle: {       // style of title in all headers in mini mode
      type: Object,
      default: () => ({})
    },
    miniValueStyle: {       // style of value in all rows in mini mode
      type: Object,
      default: () => ({})
    },
    itemHoverStyle: {       // style of hover rows
      type: Object,
      default: () => ({})
    },
    miniItemHoverStyle: {   // style of hover rows in mini mode
      type: Object,
      default: () => ({})
    },
    transformTime: {
      type: Number,
      default: 0.3
    },

    noDataText: {
      type: String,
      default: 'No Data'
    }
  },
  data () {
    return {
      hover: -1,
      myWidth: 0,
      expandIndexes: []
    }
  },
  computed: {
    isMini () {
      return this.mini || (this.myWidth <= this.miniSize)
    },
    miniFields () {
      return column => {
        return this.headers.filter(el => el.miniColumn >= column && el.miniColumn < column + 1).sort((a, b) => a.miniColumn - b.miniColumn)
      }
    },
    miniColumnsStyleFixed () {
      let ret = []
      for (let i = 0; i < this.miniColumns; i++) {
        ret.push(this.miniColumnsStyle[i] instanceof Object && !(this.miniColumnsStyle[i] instanceof Array) ? this.miniColumnsStyle[i] : {})
      }
      return ret
    }
  },
  methods: {
    enter (el) {
      el.style.transition = 'opacity ' + this.transformTime + 's'
    },
    leave (el) {
      el.style.transition = 'opacity ' + this.transformTime + 's'
    },
    onMouseOverItem (index) {
      if (this.hover !== index) this.hover = index
    },
    onMouseOutItem (index) {
      if (this.hover === index) this.hover = -1
    },
    clickItem (item, index) {
      this.$emit('click-item', { item, index, row: index + 1 })
      if ((!this.isMini && this.expand) || (this.isMini && this.expandMini)) {
        let ei = this.expandIndexes.indexOf(index)
        if (ei < 0) {
          this.expandIndexes.push(index)
        } else {
          this.expandIndexes.splice(ei, 1)
        }
      }
    },
    refreshMyWidth () {
      try {
        this.myWidth = this.$refs['flexible_list_container'].clientWidth
      } catch (e) {
        // console.log(e)
      }
    }
  },
  mounted () {
    this.refreshMyWidth()
    window.addEventListener("resize", this.refreshMyWidth)
  },
  destroyed () {
    window.removeEventListener("resize", this.refreshMyWidth)
  }
}
</script>

<style lang="sass" scoped>
  table.flexible-table
    width: 100%
    text-align: left

    tr
      align-items: center

      td
        padding: 12px
        border-top: 1px solid rgba(0, 0, 0, .12)

        div, span
          word-break: break-word
        

        &.expansion-icon
          width: 48px
        
      

      &.flexible-table-header
        td
          padding-top: 18px
          padding-bottom: 18px
          border-top: none
          border-bottom: 1px solid rgba(0, 0, 0, .12)
        
      

      &.flexible-table-first-item
        td
          border-top: none
        
      
    
  
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
    opacity: 0
  
</style>
