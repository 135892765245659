var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"fit-content","persistent":"","fullscreen":_vm.isMobile,"hide-overlay":_vm.isMobile,"transition":_vm.isMobile ? 'dialog-bottom-transition' : 'dialog-transition'},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',{staticClass:"role-card"},[_c('v-list',{class:_vm.isMobile ? ['white', 'sidebar-top'] : ['white']},[_c('v-list-item',[(_vm.isMobile)?[_c('v-list-item-action',[(_vm.isMobile)?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":_vm.closeShareTo}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-spacer')]:_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{style:(_vm.isMobile ? {'text-align': 'center'} : {'font-size': '20px'})},[_vm._v("Forward")])],1),_c('v-spacer'),_c('v-list-item-action',[(_vm.isMobile)?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":_vm.complete}},[_c('v-icon',[_vm._v("check")])],1):_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":_vm.closeShareTo}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],2)],1),_c('div',{style:({
        'position': 'absolute',
        'top': '64px',
        'bottom': '0',
        'left': '0',
        'right': '0',
        'overflow-x': 'hidden'
      })},[_c('forward-reviewer-list',{style:({
          'height': 'calc(100% - ' + (_vm.isMobile ? _vm.commentBoxRows * 18 + 47 : 260) + 'px)',
          'padding': '0 30px ' + (_vm.isMobile ? 64 : 12) + 'px 24px',
          'overflow': 'auto'
        }),attrs:{"members":_vm.myMembers},on:{"change-members":_vm.changeMembers}}),_c('div',{staticClass:"contract-review-comments-container",style:(_vm.isMobile ? { 'padding': '0 0 3px' } : { 'padding': '16px 18px 0' })},[_c('v-textarea',{attrs:{"solo":"","no-resize":"","placeholder":"Please input comments","counter":_vm.commentsMax,"rows":_vm.isMobile ? _vm.commentBoxRows : 5},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})],1),(!_vm.isMobile)?_c('div',{staticStyle:{"padding":"5px 16px","text-align":"right"}},[_c('v-btn',{attrs:{"dark":!_vm.canSend,"color":_vm.btnColor,"disabled":_vm.canSend},on:{"click":_vm.complete}},[_vm._v(" Send ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }