<template>
  <v-list class="forward-review-list">
    <v-list-item>
      <account-autocomplete
        v-model="searchedMember"
      ></account-autocomplete>
    </v-list-item>

    <v-list-item class="role-banner">
      <v-list-item-content>
        <span>Forward to <span style="color:#ccc">({{myMembers.length}})</span></span>
      </v-list-item-content>
    </v-list-item>

    <div :style="{
        'position': 'absolute',
        'top': '110px',
        'bottom': 0,
        'left': 0,
        'right': 0,
        'padding-left': '24px',
        'padding-right': (isMobile ? 30 : 12) + 'px',
        'padding-bottom': '0px',
        'overflow-y': 'scroll'
      }"
    >
      <template v-for="(member, mi) in myMembers">
        <v-list-item :key="myMembers.length - mi">
          <v-list-item-content>
            <account-profile
              :email="member.email"
              :name="member.name"
              :icon="member.icon"
              :tag-icon-size="32"
              :tag-color="themeColor.default"
              :gap-of-tag-icon-and-name="24"
              style="margin-left: 15px;"
            >
            </account-profile>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn small text icon
              @click="removeMember(mi)"
            >
              <v-icon color="grey" size="22px">
                mdi-close
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </div>
  </v-list>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AccountAutocomplete from '@/components/vuetify-extension/account-autocomplete'
import AccountProfile from '@/components/vuetify-extension/account-profile'

export default {
  components: {
    AccountAutocomplete,
    AccountProfile
  },
  props: {
    members: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      searchedMember: {
        email: null,
        name: null,
        icon: null
      },
      myMembers: [],
      autoUpdate: true
    }
  },
  computed: {
    ...mapState('app', {
      themeColor: state => state.color
    }),
    ...mapGetters('app', [
      'isMobile'
    ])
  },
  methods: {
    matchMembers (members) {
      this.myMembers = members.map(el => ({
        email: el.email || '',
        name: el.name || '',
        icon: el.icon || '',
        role: el.role || ''
      }))
    },
    removeMember (index) {
      this.myMembers.splice(index, 1)
      this.$emit('change-members', this.myMembers)
    }
  },
  created () {
    this.matchMembers(this.members)
  },
  watch: {
    members: {
      deep: true,
      handler (val, oVal) {
        this.matchMembers(val)
      }
    },
    searchedMember: {
      deep: true,
      handler (val, oVal) {
        try {
          if (val.email || val.name || val.icon) {
              try {
                if (val.email !== oVal.email || val.name !== oVal.name || val.icon !== oVal.icon) {
                  if (this.myMembers.findIndex(el => el.email === val.email && el.name === val.name && el.icon === val.icon) > -1) {
                    this.$eventBus.$emit('snackbar', { text: val.name + ' is ready in member group', type: 'warning' })
                  } else {
                    this.myMembers.splice(0, 0, {
                      email: val.email,
                      name: val.name,
                      icon: val.icon,
                      role: '1'
                    })
                    this.$emit('change-members', this.myMembers)
                  }
                }
              } catch (e) {}
              this.searchedMember = {
                email: null,
                name: null,
                icon: null
              }
          }
        } catch (e) {}
      }
    }
  }
}
</script>

<style lang="sass">
.menu-buttons
  background-color: white

  .menu-button
    cursor: pointer
    position: relative

    &:hover:before
      border-radius: inherit
      color: inherit
      content: ""
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      opacity: .12
      transition: .3s cubic-bezier(.25,.8,.5,1)
      background-color: rgba(0, 0, 0, .4)

.forward-review-list
  overflow: hidden
  position: relative

  .role-banner
    margin-top: 0px

    // span
    //   font-weight: bold

  .v-list-item
    height: auto
    padding: 6px

  .v-list-item__avatar
    margin-left: 15px
    min-width: 56px

  .v-list-item__action
    margin: 0 3px 0 6px
    min-width: 34px

  .v-select__selections
    input
      padding-bottom: 12px
      padding-left: 16px
</style>
